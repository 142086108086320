.sc-fzqBkg .sc-fzqPZZ .eDloXy{
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .sc-fzqBkg {
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .sc-fzqPZZ {
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .sc-cTkwdZ{
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .sc-cTkwdZ {
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .sc-jNMdTA {
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .sc-dOSReg {
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .kFCyzt {
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .fSquiI{
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }