.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
  border-bottom: #c4c4c4 1px solid;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.TcBAK {
  margin: auto;
  max-width: 150px !important;
  max-height: 150px !important;
}

.dwuxHx{
  display: none !important;
}

a[href="https://github.com/Redocly/redoc"] {
  display: none !important;
}
img[src="logo.jpg"] {
  margin: auto;
  max-width: 150px !important;
  max-height: 150px !important;
}

.sc-fzoCCn{
  background-color: #ffffff !important;
  border-bottom: #c4c4c4 1px solid !important;
}

/* Logo section - logo itself */
.sc-dFtzxp {
  padding: 20px !important;
}

/* Logo section - logo container */
.sc-brSvTw {
  /* padding: 20px 0 0px 0; */
  background-color: #f6f8fa !important;
}

/* TOC bg-color */
.sc-dwsnSq {
  background-color: #f6f8fa !important;
}
/* TOC active state color */
.sc-dkYRCH.active {
  background-color: #d0d7de !important;
}
/* TOC active state color (hover state) */
.sc-dkYRCH:hover {
  background-color: #d0d7de !important;
}

/* Section */
.sc-eCImPb {
  padding: 0 !important;
}

/* Sub-heading container */
.sc-hKwDye {
  padding-top: 0  !important;
  padding-bottom: 0 !important;
}

/* H2 - bottom border & styling */
.sc-pVTFL {
  border-bottom: 1px solid hsla(210,18%,87%,1);
  font-weight: bold !important;
  color: #24292f !important;
  margin-bottom: 0;
  margin-top: 0;
}

/* Heading (h1) */
.sc-furwcr {
  margin-top: 48px !important;
  color: #24292f !important;
  border-bottom: 1px solid hsla(210,18%,87%,1);
}

h3 {
  margin-top: 30px !important;
  margin-bottom: 0 !important;
  color: #373c41 !important;
}

/* responses h3 */
.sc-htJRVC{
 font-size: 1.17em !important;
 font-weight: bold !important;
 margin-bottom: 14px !important;
}

/* Contact section */
.sc-ctqQKy {
  margin-top: 8px;
}

hr {
  height: 0.25em;
  background-color: #f6f8fa;
  border: 0;
  margin: 40px 0 0 0;
}

/* inner headings for los details */
.sc-iqseJM {
  background-color: #f6f8fa !important;
  border-bottom: none !important;
  padding: 10px !important;
  color: #616569 !important;
  margin: 0 0 10px 0 !important;
}

.sc-fIosxK {
  padding-top: 12px;
}

blockquote {
  color: #57606a !important;
  border-left: 0.25em solid #d0d7de !important;
}

@media print, screen and (max-width: 75rem) {
  .sc-jRQBWg {
    margin-top: 40px;
  }
}


/* los section hr */
@media print, screen and (min-width: 75rem) {
  .sc-jRQBWg {
    padding: 40px !important;
  }
}

a {
  color: #0969da !important;
}
