h1 {
  font-size: 28px !important;
  font-weight: 600 !important;
}

h2{
  padding-top: 30px !important;
}

/* h5 - styling for the background block & font specifications */
h5 {
  background-color: #f6f8fa;
  font-weight: normal !important;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 26px !important;
  line-height: 26px !important;
  font-size: 16px !important;
}

hr {
  background-color: #f6f8fa !important;
}

.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 980px;
    margin: 0 auto;
    padding: 45px;
    text-align: left;
    /* left: 260px; */
    position: relative;
  }

  img[src="./logo_smaller.png"] {
    max-height: 150px;
  }

  @media (max-width: 767px) {
    .markdown-body {
      padding: 15px;
    }
  }

  .markdown-body:before{
    content: "";
    display: block;
    background: url('../assets/logo.png') no-repeat;
    background-position: center;
    background-size: contain;
    /* background-color:#313B45; */
    height: 100px;
    /* padding-top: 20pt; */
    /* padding-bottom: 20pt; */
    padding-left: 0pt;
    /* border-bottom: #c4c4c4 1px solid; */
    margin: auto;
    margin-bottom: 30px;
  }

  #table-of-contents + ul:before{
    content: "";
    display: block;
    background: url('../assets/logo.png') no-repeat;
    background-position: center;
    background-size: contain;
    background-color:#313B45;
    height: 100px;
    /* padding-top: 20pt; */
    /* padding-bottom: 20pt; */
    padding-left: 0pt;
    border-bottom: #c4c4c4 1px solid;
    margin: auto;
  }
  #table-of-contents + ul {
    float: left;
    position: fixed;
    left: 0px;
    padding-left: 0pt;
    height: 100%;
    top: 0px;
    list-style: none;
    color: #212121;
    max-width: 260px;
    background-color: #FAFAFA;
  }

  #table-of-contents + ul > li {
    list-style: none;
    color: #212121;
    font-size: 10pt;
    padding-left: 20pt;
    /* padding-top: 10pt; */
  }


  #table-of-contents + ul > li {
    list-style: none;
    color: #212121;
    font-size: 10pt;
  }

  #table-of-contents + ul > li  * {
    list-style: none;
    color: #282c31;
    font-size: 10pt;
    /* padding-top: 5pt; */
  }


  #table-of-contents {
    display: none;
  }

  @media (max-width: 1500px) {
    .markdown-body {
      margin: 0;
      left: 260px;
    }
  }

  @media (max-width: 450px) {
    .markdown-body {
      margin: 0 auto;
      left: 0px;
    }
    #table-of-contents + ul {
      display: none;
    }
  }